html body .p-0 {
  padding: 0px
}

html body .p-10 {
  padding: 10px
}

html body .p-20 {
  padding: 20px
}

html body .p-30 {
  padding: 30px
}

html body .p-l-0 {
  padding-left: 0px
}

html body .p-l-10 {
  padding-left: 10px
}

html body .p-l-20 {
  padding-left: 20px
}

html body .p-r-0 {
  padding-right: 0px
}

html body .p-r-10 {
  padding-right: 10px
}

html body .p-r-20 {
  padding-right: 20px
}

html body .p-r-30 {
  padding-right: 30px
}

html body .p-r-40 {
  padding-right: 40px
}

html body .p-t-0 {
  padding-top: 0px
}

html body .p-t-10 {
  padding-top: 10px
}

html body .p-t-20 {
  padding-top: 20px
}

html body .p-t-30 {
  padding-top: 30px
}

html body .p-b-0 {
  padding-bottom: 0px
}

html body .p-b-5 {
  padding-bottom: 5px
}

html body .p-b-10 {
  padding-bottom: 10px
}

html body .p-b-20 {
  padding-bottom: 20px
}

html body .p-b-30 {
  padding-bottom: 30px
}

html body .p-b-40 {
  padding-bottom: 40px
}

html body .m-0 {
  margin: 0px
}

html body .m-l-5 {
  margin-left: 5px
}

html body .m-l-10 {
  margin-left: 10px
}

html body .m-l-15 {
  margin-left: 15px
}

html body .m-l-20 {
  margin-left: 20px
}

html body .m-l-30 {
  margin-left: 30px
}

html body .m-l-40 {
  margin-left: 40px
}

html body .m-r-5 {
  margin-right: 5px
}

html body .m-r-10 {
  margin-right: 10px
}

html body .m-r-15 {
  margin-right: 15px
}

html body .m-r-20 {
  margin-right: 20px
}

html body .m-r-30 {
  margin-right: 30px
}

html body .m-r-40 {
  margin-right: 40px
}

html body .m-t-0 {
  margin-top: 0px
}

html body .m-t-5 {
  margin-top: 5px
}

html body .m-t-10 {
  margin-top: 10px
}

html body .m-t-15 {
  margin-top: 15px
}

html body .m-t-20 {
  margin-top: 20px
}

html body .m-t-30 {
  margin-top: 30px
}

html body .m-t-40 {
  margin-top: 40px
}

html body .m-b-0 {
  margin-bottom: 0px
}

html body .m-b-5 {
  margin-bottom: 5px
}

html body .m-b-10 {
  margin-bottom: 10px
}

html body .m-b-15 {
  margin-bottom: 15px
}

html body .m-b-20 {
  margin-bottom: 20px
}

html body .m-b-30 {
  margin-bottom: 30px
}

html body .m-b-40 {
  margin-bottom: 40px
}

html body .vt {
  vertical-align: top
}

html body .vm {
  vertical-align: middle
}

html body .vb {
  vertical-align: bottom
}

.op-5 {
  opacity: 0.5
}

html body .font-bold {
  font-weight: 700
}

html body .font-normal {
  font-weight: normal
}

html body .font-light {
  font-weight: 300
}

html body .font-medium {
  font-weight: 500
}

html body .font-16 {
  font-size: 16px
}

html body .font-14 {
  font-size: 14px
}

html body .font-10 {
  font-size: 10px
}

html body .font-18 {
  font-size: 18px
}

html body .font-20 {
  font-size: 20px
}

html body .b-0 {
  border: none
}

html body .b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13)
}

html body .b-l {
  border-left: 1px solid rgba(120, 130, 140, 0.13)
}

html body .b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13)
}

html body .b-t {
  border-top: 1px solid rgba(120, 130, 140, 0.13)
}

html body .b-all {
  border: 1px solid rgba(120, 130, 140, 0.13) !important
}

.thumb-sm {
  height: 32px;
  width: 32px
}

.thumb-md {
  height: 48px;
  width: 48px
}

.thumb-lg {
  height: 88px;
  width: 88px
}

.hide {
  display: none
}

.img-circle {
  border-radius: 100%
}

.radius {
  border-radius: 4px
}


.ngx-pagination {
  padding-left: 0px !important;
}

.ngx-pagination .current,
.btn-info {
  background: #1eb1fd !important;
  border: transparent !important;
  border-radius: 20px !important;
}

.ngx-pagination .current:hover {
  background: #1eb1fd !important;
  border-radius: 20px !important;
  border: transparent !important;
}

circle.node {
  cursor: pointer;
  stroke: #34495e;
  stroke-width: 2px;
  box-sizing: border-box;
  stroke-location: inside;
}

line.link {
  fill: none;
  stroke: #34495e;
  stroke-width: 1.5px;
}

text.title {
  margin-right: 10px;
}

.bg-theme {
  background-color: #37bafd;
}

.my-legend .legend-title {
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 90%;
}

.my-legend .legend-scale ul {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  float: left;
  list-style: none;
}

.my-legend .legend-scale ul li {
  font-size: 80%;
  list-style: none;
  margin-left: 0;
  line-height: 18px;
  margin-bottom: 2px;

}

.my-legend ul.legend-labels li span {
  display: block;
  float: left;
  height: 16px;
  width: 30px;
  margin-right: 5px;
  margin-left: 0;
  border: 1px solid #999;
}

.my-legend .legend-source {
  font-size: 70%;
  color: #999;
  clear: both;
}

.my-legend a {
  color: #777;
}

.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 1px;
}

.node text {
  font: 12px sans-serif;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}

.node {
  cursor: pointer;
}

.customtab2 li a.nav-link {
  border: 0px;
  margin-right: 3px;
  color: #67757c
}

.customtab2 li a.nav-link.active {
  background: #1eb1fd;
  color: #ffffff
}

.customtab2 li a.nav-link:hover {
  color: #ffffff;
  background: #1eb1fd
}

.k-panelbar>.k-item>.k-link.k-state-selected {
  color: #ffffff;
  background-color: #1eb1fd;
}

.k-panelbar>.k-item>.k-link.k-state-selected:hover,
.k-panelbar>.k-item>.k-link.k-state-selected.k-state-hover {
  color: #ffffff;
  background-color: #1eb1fd;
}

.k-panelbar>.k-item>.k-link {
  color: #ffffff;
  background-color: #ffffff;
}

.k-panelbar>.k-item>.k-link:hover {
  color: #ffffff;
  background-color: #1eb1fd;
}

.k-panelbar>.k-item>.k-link {
  color: #ffffff;
  background-color: #1eb1fd;
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  color: #ffffff;
  background-color: #1eb1fd;

}

.k-panelbar .k-group>.k-item>.k-link.k-state-selected {
  color: #fff;
  background-color: #1eb1fd;

}

.k-panelbar .k-group>.k-item>.k-link.k-state-selected:hover {
  color: #fff;
  background-color: #1eb1fd;
}

.node-history .k-panelbar-expand::before {
  content: "\e11e";
}

.node-history .k-panelbar-collapse::before {
  content: "\e121";
}


.k-text-selection ::selection,
.k-block ::selection,
.k-panel ::selection,
.k-pane-wrapper ::selection,
.k-view ::selection,
.k-progressbar ::selection,
.k-numerictextbox ::selection,
.k-flatcolorpicker ::selection,
.k-combobox ::selection,
.k-dateinput ::selection,
.k-datepicker ::selection,
.k-datetimepicker ::selection,
.k-timepicker ::selection,
.k-dropdown ::selection,
.k-dropdowntree ::selection,
.k-multiselect ::selection,
.k-maskedtextbox ::selection,
.k-rating ::selection,
.k-window ::selection,
.k-drawer ::selection,
.k-notification ::selection,
.k-breadcrumb ::selection,
.k-pager-wrap ::selection,
.k-panelbar ::selection,
.k-card ::selection,
.k-splitter ::selection,
.k-spreadsheet ::selection,
.k-scheduler ::selection,
.k-chat ::selection,
.k-mediaplayer ::selection,
.k-pdf-viewer ::selection,
kendo-scrollview.k-scrollview-wrap ::selection,
div.k-scrollview ::selection,
.k-chart ::selection,
.k-sparkline ::selection,
.k-stockchart ::selection {
  background-color: #007bff;
  color: #fff;
}

.dropdown-list {
  position: relative !important;
}

.k-button-group .k-button:active,
.k-button-group .k-button.k-state-active,
.k-button-group .k-button.k-state-selected,
.k-button-group>input[type="radio"]:checked+.k-button,
.k-button-group>input[type="checkbox"]:checked+.k-button {
  border-color: #30678d;
  color: #ffffff;
  background-color: #30678d;
  background-image: linear-gradient(rgba(255, 138, 88, 0.2), rgba(255, 138, 88, 0));
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.06);
}

.k-calendar .k-footer .k-nav-today,
.k-calendar .k-calendar-header .k-today {
  color: #30678d;
}

.k-button.k-primary {
  border-color: #30678d;
  color: #ffffff;
  background-color: #30678d;
  background-image: linear-gradient(rgba(255, 138, 88, 0), rgba(255, 138, 88, 0.2));
}

.k-time-header .k-time-now {
  color: #007bff;
  background: transparent;
}

.k-calendar .k-footer .k-nav-today:hover,
.k-calendar .k-footer .k-nav-today:focus,
.k-calendar .k-calendar-header .k-today:hover,
.k-calendar .k-calendar-header .k-today:focus {
  color: #0056b3;
}

.k-calendar .k-content .k-today .k-link,
.k-calendar .k-calendar-content .k-today .k-link {
  box-shadow: inset 0 0 0 1px #007bff;
}

.k-calendar .k-content .k-today.k-state-focused .k-link,
.k-calendar .k-calendar-content .k-today.k-state-focused .k-link {
  box-shadow: inset 0 0 0 1px #007bff, inset 0 0 0 3px rgba(228, 231, 235, 0.5);
  padding-bottom: 0 !important;
}

.k-calendar .k-state-selected .k-link {
  border-color: #005dc2;
  color: #ffffff;
  background-color: #007bff;
}

.k-calendar .k-state-selected.k-state-hover .k-link,
.k-calendar .k-state-selected:hover .k-link {
  border-color: #0056b2;
  color: #ffffff;
  background-color: #0071eb;
}

.k-calendar .k-calendar-navigation li:hover {
  color: #0056b3;
}

.k-button.k-primary:hover,
.k-button.k-primary.k-state-hover {
  border-color: #0062cc;
  color: #ffffff;
  background-color: #0069d9;
}

.k-time-header .k-time-now:hover,
.k-time-header .k-time-now:focus {
  color: #0056b3;
}

.k-time-list .k-item:hover {
  color: #007bff;
}

.k-calendar .k-content .k-today,
.k-calendar .k-calendar-content .k-today {
  color: #007bff;
}


.interface .k-panelbar-expand::before {
  content: "\e11e";
}

.interface .k-panelbar-collapse::before {
  content: "\e121";
}

/* Sets button text when clicked. Should combine these later with wildcards */
#subs-table .btn-primary:before {
  content: '\e11e';
}

#subs-table .btn-primary[aria-expanded="true"]:before {
  content: '\e121';
}

/* Styling of the collapsible row */
#subs-table td.collapse-row {
  border-style: none;
  padding: 0;

}

#subs-table .collapse-header {
  background-color: #f5f5f5;
}

#date-picker {
  width: 170px;
}

.deployment-progress .k-grid tr.virtual {
  background-color: #f5f5dc;
}

.deployment-progress .k-grid tr.success-row {
  background-color: #73e773;
  color: black;
}

.deployment-progress .k-grid tr.failed-row {
  background-color: #ff6262;
  color: black;

}

.deployment-progress .k-grid tr.pending-row {
  background-color: #63a3e7;
}

.deployment-progress .k-grid tr.inprogress-row {
  background-color: #868e96;
  color: white;
}

.deployment-progress .k-grid tr.ignore-row {
  background-color: #ffc107;

}

.deployment-progress .k-grid tr.default-row {
  background-color: white;
}

.deployment-progress .k-grid tr.onhold-row {
  background-color: #73d0ff;
}




.k-master-row td.k-hierarchy-cell .k-plus::after {
  content: "\f067";
  font-family: 'FontAwesome'
}

.k-master-row td.k-hierarchy-cell .k-minus::after {
  content: "\f068";
  font-family: 'FontAwesome'
}

.k-item.k-state-selected.k-state-focused:not(:hover) {
  color: #ffffff !important;
  background-color: #3ea0d1 !important;
}

.k-item.k-state-selected.k-state-focused :hover {
  color: #ffffff !important;
  background-color: #3ea0d1 !important;
}

.kendo-treeview {
  width: 1000px !important;
  overflow: none !important;
}

.k-textbox {
  border-color: rgba(0, 0, 0, 5) !important;
  color: #0e0202;

}

.k-treeview {
  font-size: 17px !important;
  margin-left: 100px !important;
  margin-bottom: 10px !important;
}

.k-dateinput,
.k-datepicker,
.k-datetimepicker,
.k-timepicker {
  width: 100% !important;
}

.bg-skyblue {
  background-color: #73d0ff;
}

.text-skyblue {
  color: #73d0ff;
}

.bg-red {
  background-color: #ff6262;
}

.text-red {
  color: #ff6262;
}

.bg-green {
  background-color: #73e773;
}

.text-green {
  color: #73e773;
}
.modal-lg {
  min-width: 1400px;
}
/* Custom style for the Browse button */
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
  background-color: #3598cb !important; /* Change background color here */
  color: white; /* Change text color */
  border-radius: 5px; /* Optional: Add rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
}
/* .k-grid td {
  white-space: pre;
} */