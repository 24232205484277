/* You can add global styles to this file, and also import other style files */
$series-a :#1eb1fd;
$grid-header-bg : rgba(36, 53, 109, 0.93);
$grid-header-text : #fff;
@import 'authPages.scss';

@media print {
    .breadcrumb {
        display: none !important;
    }
}

.k-grid-header .k-header {
    background-color: #30678d;
    color: whitesmoke;


}

tr.k-state-selected>td {
    background-color: #3598cb !important;
    color: whitesmoke;
}

.k-checkbox:checked {
    background-color: #3598cb !important;
    color: whitesmoke;
    border-color: #30678d !important;

}


.customtab2 li a.nav-link.active {
    background-color: #3598cb !important;

}

.customtab2 .li a.nav-link {
    color: #2a93c9 !important;
    border-color: #30678d !important;

}

.customtab2 .li {
    border-color: #30678d !important;
}


.card-header {
    background-color: #30678d !important;
    color: #fff !important;
    height: 38px !important;
    display: flex;
    align-items: center;
}

.breadcrumb {
    background-color: #c1c8cf !important;
}

.breadcrumb-item.active {
    color: #505a5c !important;
}


.navbar {
    background-color: #05304e !important;
}

.sidebar {
    background-color: #05304e !important;
}

.bg-theme {
    background-color: #2a93c9 !important;
}

.sidebar>.list-group>a.router-link-active {
    background-color: #2a93c9 !important;
}

.sidebar>.list-group {
    background-color: #05304e !important;
}

.sidebar>.list-group>a {
    background-color: #05304e !important;
}

.toggle-button {
    background-color: #05304e !important;
}

a.list-group-item {
    background-color: #05304e !important;
}

button.list-group-item {
    background-color: #05304e !important;
}

.span {
    background-color: #2a93c9 !important;
}

.k-grid a {
    color: #2356a5 !important;

}

.card-header {
    background-color: #30678d !important;
}

.customtab2 li a.nav-link:hover {
    color: #ffffff;
    background-color: #3ea0d1 !important;
}

.customtab2 li button.nav-link:hover {
    color: #ffffff;
    background-color: #3ea0d1 !important;
}


.btn-outline-primary {
    color: #2a93c9 !important;
    border-color: #30678d !important;

}

.btn-outline-primary:hover {
    color: #ffffff !important;
    background-color: #3ea0d1 !important;
}

.customtab2 li a {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #2a93c9;
    border-color: #30678d !important;


}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: white;
    background-color: #3598cb !important;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link.nav-link.active {
    color: white !important;
    background-color: #3598cb !important;

}

.btn-primary {
    color: #fff;
    background-color: #3598cb !important;
    border-color: #30678d !important;
}

.k-panelbar>.k-item>.k-link {
    color: #ffffff;
    background-color: #30678d !important;
}

.k-panelbar ::selection,
.k-splitter ::selection {
    background-color: #30678d !important;
    color: #fff;
}

.k-dropdown-wrap .k-state-default {
    color: #ffffff !important;
    background-color: #3ea0d1 !important;
}

.k-dropdown-wrap .k-state-default :hover {
    color: #ffffff !important;
    background-color: #3ea0d1 !important;
}

.fota-card {
    margin-top: 0;
    margin-bottom: 1.5rem;
    text-align: left;
    position: relative;
    background: #fff;
    box-shadow: 12px 15px 20px 0px rgba(46, 61, 73, 0.15);
    border-radius: 4px;
    transition: all 0.3s ease;
    
}

.fota-card .fa {
    position: relative;
    font-size: 70px;
}

.fota-card-topimage_header {
    padding: 20px;

    i {
        color: $primary-dark-color;
    }
}

.fota-card-topimage_header.fota-device {
    background-color: #9bf6ff;
}

.fota-card-topimage_header.fota-group {
    background-color: #a9d6e5;
}

.fota-card-topimage_header.fota-art {
    background-color: #abc4ff;
}

.fota-card-topimage_header.fota-deploy {
    background-color: #a2d2ff;
}

a.fota-card:hover,
.fota-card-topimage:hover {
    box-shadow: 2px 4px 8px 0px rgba(46, 61, 73, 0.2);
}

.fota-card-topimage a {
    width: 100%;
    height: 100%;
    display: block;
}

.fota-card-topimage_title {
    padding: 20px 24px;
    height: 80px;
    padding-bottom: 0.75rem;
    position: relative;
}

.fota-card-topimage a {
    border-bottom: none;
    text-decoration: none;
    color: #525c65;
    transition: color 0.3s ease;
}

.x_panel {
    width: 100%;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #e6e9ed;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all 0.2s ease;
}

.x_title {
    border-bottom: 2px solid #e6e9ed;
    padding: 1px 5px 6px;
}

.x_title .filter {
    width: 40%;
    float: right;
}

.x_content,
table.tile td ul li a,
table.tile_info {
    width: 100% !important;
}

.x_title h2 {
    margin: 5px 0 6px;
    float: left;
    display: block;
}

.x_title h2 small {
    margin-left: 10px;
}

.x_title span {
    color: #bdbdbd;
}

.x_content {
    padding: 0 5px 6px;
    float: left;
    clear: both;
    margin-top: 5px;
}

.x_content h4 {
    font-size: 16px;
    font-weight: 500;
}

.fixed_height_250 {
    height: 240px !important;
}

.degrees:after,
.x_content,
.x_panel {
    position: relative;
}


.k-dropdown {
    width: 200px !important;
    height: 30px !important;
}

.k-switch-on .k-switch-container {
    color: #ffffff;
    background-color: #30678d !important;
}

.list-group-item.active {
    color: #ffffff;
    background-color: #30678d !important;
    border-color: #30678d !important;
}

.btn-outline-primary.active {
    color: #ffffff !important;
    background-color: #30678d !important;
    border-color: #30678d !important;
    box-shadow: 0 0 0 0rem !important;
}

.btn-outline-primary:hover {
    color: #ffffff !important;
    background-color: #30678d !important;
    border-color: #30678d !important;

}

.batteryPercentage {
    font-size: 32px;
}

.batteryQuartes {
    color: #f5de08;

}

.dashboard-pink {
    background-color: #da746c !important;
}

.dashboard-gray {
    background-color: #e0e0e0 !important;
}

.map-data {
    width: 100%;
    height: 700px;
}