$auth-input-bottom-border-color: rgb(156 174 195 / 40%);
$primary-color: #1eb1fd;
$primary-dark-color: #0a2f5b;
$theme-colors: (
    "primary": $primary-color,
    "dark": $primary-dark-color,
);

.authentication {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background: #0a2e5a;
    text-align: center;
    color: #fff;
    padding: 3em;

    .container {
        background: $primary-dark-color;
        border-radius: 0.5rem 2rem 0.5rem 2rem;
        box-shadow: 12px 12px 16px 0 rgb(0 0 0 / 25%), -6px -8px 25px 0 rgb(255 255 255 / 11%);

    }

    .auth-image {
        height: 100%;
        width: 100%;
        $white-space: nowrap;
        text-align: center;

        .img-helper {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }

    input,
    .auth-input {
        height: 46px;
        padding: 10px 0px;
        font-size: 18px;
        line-height: 1.3333333;
        border-radius: 0;
        background: 0 0;
        border: none;
        box-shadow: none;
        border-bottom: 2px solid $auth-input-bottom-border-color;
        color: #fff;
        border-radius: 0;
        text-align: left;
        width: 100%;

        &:focus {
            outline: none;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: none;
        box-shadow: none;
        border-bottom: 2px solid $auth-input-bottom-border-color;
        animation: opac 0.8s;
    }

    form {
        background: #567fa926;
        border: 1px solid #2a578a5c;
        border-radius: 0.5rem 2rem 0.5rem 2rem;

        .input-intl-tel-in {
            &:focus {
                color: #fff !important;
               // border-color: $auth-input-bottom-border-color !important;
               background-color:#05304e!important;
                outline: 0 !important;
                box-shadow: none !important;
            }
        }

        .secondary-button {
            border: none;
            background: #0a2f5b69;
        }
    }

    .modal-body {
        color: black;
    }

    .show-icon {
        float: right !important;
        left: -15px;
        margin-top: -25px;
        position: relative;
        z-index: 2;
        cursor: pointer;
    }

    .modal-header {
        padding: 0.5rem 1rem;
       // background: $primary-dark-color;
        background-color:#05304e!important;
        color: #fff;

        .modal-title {
            margin-bottom: 0 !important;
        }

        .close {
            color: #fff;
        }
    }

    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        outline: 0;
    }
}